body {
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div {
  box-sizing: border-box;
}

.flashcard {
  width: 750px;
  height: 400px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 25px;
  text-align: center;
}

.counter {
  margin-bottom: 15px;
}

.controls {
  max-width: 750px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
  grid-column-gap: 15px;
  button {
    border: none;
    border-radius: 10px;
    background-color: white;
    font-size: 24px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
}
